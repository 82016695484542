<template>
  <div class="container">
    <div class="header">
      <img class="book-img" :src="bookInfo.coverUrl" width="100%" height="100%" alt="">
      <div class="book-info">
        <div class="title">{{bookInfo.name}}</div>
        <div class="book-tag">
          <div class="tag">{{bookInfo.subjectName}}</div>
          <div class="tag" v-for="(item, index) in bookInfo.gradeIds" :key="index" >{{gradeData[item]}}</div>
          <div class="tag">{{bookInfo.cyberArticleName}}</div>
        </div>
        <div class="book-edition">{{ bookInfo.textbookVersionName}}</div>
      </div>
    </div>
    <div class="supporting">
      <div class="title">
        <img src="@/assets/icon-left.png" alt="" class="icon-img icon-left">
        <span class="title-text">本书配套</span>
        <img src="@/assets/icon-left.png" alt="" class="icon-img icon-left">
      </div>
      <div class="listen-said">
        <img src="@/assets/listen-said.jpg" alt="" class="icon-img">
        <div class="listen-said-info">
          <div class="listen-said-title">粤教听说</div>
          <div class="listen-said-introduction">一站式搞定听、读、背、默</div>
        </div>
        <div class="platforms">
          <span>相关平台：</span>
          <img src="../assets/apple.png" alt="" class="platforms-icon">
          <img src="../assets/android.png" alt="" class="platforms-icon">
        </div>
      </div>
      <div class="img-scorll">
        <img :src="require('@/assets/banner.png')" alt="" class="banner-img">
        <!--<template v-for="imgs in imgList">-->
        <!--  <img :key="imgs.id" :src="imgs.url" alt="">-->
        <!--</template>-->
      </div>
    </div>
    <div class="footer">
      <div class="footer-btn" @click="toStyle">开始学习</div>
    </div>
  </div>
</template>

<script>
import { bookDetail, generateSchemeToMiniApp } from '@/api/home'

export default {
  name: 'HomeView',
  data () {
    return {
      message: 'Hello Vue!',
      bookInfo: {},
      imgList: [],
      bookId: null,
      gradeData: {
        101: '一年级',
        102: '二年级',
        103: '三年级',
        104: '四年级',
        105: '五年级',
        0: '六年级',
        1: '七年级',
        2: '八年级',
        3: '九年级',
        4: '高一',
        5: '高二',
        6: '高三',
        7: '初中',
        8: '高中'
      }
    }
  },
  created () {
    document.title = '粤教听说'
    this.bookId = this.getUrlParam('bookId')
    this.getBookInfo(this.bookId)
  },
  methods: {
    // 书籍详情
    getBookInfo (id) {
      bookDetail({ id }).then(res => {
        if (res.state !== 11) return
        this.bookInfo = res.data
        document.title = this.bookInfo.name || '粤教听说'
      }).catch(() => {
        document.title = '粤教听说'
      })
    },
    // 解析url
    getUrlParam (name) {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      const r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
    // 去学习
    toStyle () {
      this.getAppMinipath()
    },
    // 获取小程序地址
    getAppMinipath () {
      const data = {
        path: '/pages/book-details/bookDetail',
        query: `id=${this.bookId}`,
        envVersion: process.env.VUE_APP_BASE_APP_MINI_ENV_VERSION
      }
      generateSchemeToMiniApp(data).then(res => {
        if (res.state !== 11) return
        if (res.data.openLink) window.location.href = res.data.openLink
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  position:relative;
  .header{
    box-sizing: border-box;
    height: 148px;
    padding: 14px 0;
    display: flex;
    margin: 0 14px;
    border-bottom: 1px solid #E5E5E5;
    text-align: left;
    img{
      //width: 1rem;
      width: 86px;
      height: 120px;
      margin-right: 10px;
    }
    .book-info{
      padding: 4px 0 4px 10px;
      color: #333333;
      width: 250px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      .book-title{
        flex: 1;
        font-size: 17px;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .book-tag{
        display: flex;
        overflow-x: auto;
        padding-bottom: 10px;
        margin-bottom: -10px;
        .tag{
          padding: 4px 10px;
          background-color: #E8F1FF;
          color: #1976FF;
          margin-right: 10px;
          border-radius: 4px;
          white-space: nowrap;
          &:last-child{
            margin-right: 0;
          }
        }
      }
      .book-edition{
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  .supporting{
    padding: 0 14px;
    .title{
      font-size: 17px;
      line-height: 24px;
      font-weight: bold;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      .icon-img{
        width: 24px;
        height: 20px;
      }
      .title-text{
        margin: 0 14px;
      }
    }
    .listen-said{
      border: 1px solid #EFEFEF;
      border-radius: 14px;
      padding: 14px;
      display: flex;
      margin-bottom: 14px;
      position: relative;
      background-color: #FFFFFF;
      .icon-img{
        width: 58px;
        height: 58px;
        margin-right: 14px;
      }
      .listen-said-info{
        text-align: left;
        padding: 6px 0;
        .listen-said-title{
          font-size: 17px;
          line-height: 24px;
          color: #333333;
          margin-bottom: 4px;
        }
        .listen-said-introduction{
          font-size: 13px;
          line-height: 18px;
          color: #999999;
        }
      }
      .platforms{
        position: absolute;
        top: 0;
        right: 24px;
        //width: 110px;
        height: 26px;
        font-size: 10px;
        line-height: 14px;
        padding: 6px 14px;
        border-radius: 0 0 8px 8px;
        background-color: #F6F6F6;
        display: flex;
        align-items: center;
        justify-content: center;
        .platforms-icon{
          width: 14px;
          height: 14px;
          margin-right: 4px;
        }
        &:before{
          content: '';
          width: 12px;
          height: 12px;
          background: url("../assets/angle-left.png");
          background-size: 12px 12px;
          position: absolute;
          top: 0;
          left: -12px;
        }
        &:after{
          content: '';
          width: 12px;
          height: 12px;
          background: url("../assets/angle-right.png");
          background-size: 12px 12px;
          position: absolute;
          top: 0;
          right: -12px;
        }
      }
    }
    .img-scorll{
      width: 347px;
      height: 564px;
      overflow-x: auto;
      font-size: 0;
      //display: flex;
      .banner-img{
        width: 1528px;
        height: 500px;
      }
      //img{
      //  width: 300px;
      //  height: 412px;
      //  margin-right: 14px;
      //  border-radius: 12px;
      //  &:last-child{
      //    margin-right: 0;
      //  }
      //}
    }
  }
  .footer{
    padding: 10px 14px 14px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #ffffff;
    .footer-btn{
      height: 40px;
      border-radius: 20px;
      background-color: #FE1714;
      color: #FFFFFF;
      font-size: 15px;
      line-height: 40px;
    }
    //display: flex;
    //justify-content: space-between;
    //align-items: center;
  }
}
</style>
