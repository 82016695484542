import request from '@/utils/request'

/**
 * 书籍基础信息
 * @data {*} data
 */
export function bookDetail (data) {
  return request({
    url: '/apiv2/nfclass/book/base',
    method: 'post',
    data
  })
}

/**
 * 获取小程序跳转地址
 * @data {*} data
 */
export function generateSchemeToMiniApp (data) {
  return request({
    url: '/apiv2/nfclass/wechat/generateSchemeToMiniApp',
    method: 'post',
    data
  })
}
